<template>
<div>
  <a-modal :visible="visible" title="修改内容" footer="" width="30%" destroyOnClose @cancel="cancel">
  <ly-formList :fromList="changeFeeFromList" @cancel="cancel"  @getVal="ok"></ly-formList>
  </a-modal>
</div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})

const emit  = defineEmits(['ok','update:visible'])
const  ok = (val) => {
  emit('ok', val)
}

const cancel = () => {
  emit('update:visible', false)
}
const changeFeeFromList = ref([
  {
    label: '金额',
    name: 'changeFee',
    type: 'inputNumber',
    placeholder: '请输入',
    value: null,
    prop: 'changeFee',
    width: '100%',
    rules: [
      {
        required: true,
        message: '请输入',
        trigger: 'blur',
        type: 'number'
      },
    ]
  },
  {
    label: '备注',
    name: 'reason',
    type: 'textarea',
    placeholder: '请输入',
    value: null,
    prop: 'reason',
    width: '100%',
    rules: [
      {
        required: false,
        message: '请输入',
        trigger: 'blur'
      },
    ]
  },
]) 

</script>

<style lang="less" scoped>

</style>